<template>
    <div>
                        <div class="form-check form-check-inline">
                            <input 
                                class="form-check-input" 
                                type="radio" 
                                :name="prefix + '_' + 'zone_type'" 
                                :id="prefix + '_' +'urbain'" 
                                v-model="choice"
                                :value="1"
                                checked
                            >
                            <label 
                                class="form-check-label" 
                                :for="prefix + '_' +'urbain'"
                            >
                                Urbain
                            </label>
                            <input 
                                class="form-check-input ml-3" 
                                type="radio" 
                                :name="prefix + '_' + 'zone_type'"
                                :id="prefix + '_' +'rural'"
                                v-model="choice"
                                :value="0"
                            >
                            <label 
                                class="form-check-label" 
                                :for="prefix + '_' +'rural'"
                            >
                                Rural
                            </label>
                        </div>
                    <transition name="fade" >

                        <div v-if="isRural">


                            <div class="form-group">
                                <label :for="prefix + '_' + 'territory'" class="col-form-label text-md-right">
                                    Teritoire
                                </label>

                                <select 
                                    :id="prefix + '_' + 'territory'" 
                                    class="form-control form-control-sm me-input-text"
                                    :name="prefix + '_' + 'territory'"
                                    required 
                                    v-model="territory"
                                >
                                <option 
                                    :value="null" 
                                    selected
                                >
                                    Choisir...
                                </option>

                                <option
                                    v-for="territory in concreteTerritories"
                                    :value="territory.id"
                                    :key="territory.id"
                                    v-text="territory.name"
                                >
                                    
                                </option>
                                </select>

                                <div class="">
                                        <span 
                                            class="invalid-feedback" 
                                            role="alert"
                                        >
                                            <strong></strong>
                                        </span>
                                </div>
                            </div>


                            <div 
                                class="form-group "
                            >
                                <label 
                                    :for="prefix + '' + 'sector'" 
                                    class=" col-form-label text-md-right"
                                >
                                    
                                    Secteur ou groupement

                                </label>



                                <div class="">


                                    <select 
                                        id="prefix + '' + 'sector'" 
                                        class="form-control form-control-sm me-input-text"
                                        :name="prefix + '_' + 'sector'"
                                        v-model="sector"
                                        required 
                                    >

                                        <option 
                                            :value="null" 
                                            selected
                                        >
                                            Choisir...
                                        </option>

                                        <option
                                            v-for="sector in concreteSectors"
                                            :value="sector.id"
                                            :key="sector.id"
                                            v-text="sector.name"                                        
                                        >                                            
                                        </option>
                                    </select>

                                        <span class="invalid-feedback" role="alert">
                                            <strong></strong>
                                        </span>
                                </div>
                            </div>

                        </div>

                    </transition>


                    <transition name="fade" >

                        <div v-if="isUrbain">




                            <div class="form-group ">
                                <label 
                                    :for="prefix + '_' + 'city'" 
                                    class=" col-form-label text-md-right"
                                >
                                    Ville

                                </label>

                                <div class="">

                                    <select 
                                        :id="prefix + '_' + 'city'" 
                                        class="form-control form-control-sm me-input-text"
                                        :name="prefix + '_' + 'city'"
                                        required
                                        v-model="city"
                                    >

                                        <option 
                                            :value="null" 
                                            selected
                                        >
                                            Choisir...
                                        </option>

                                        <option
                                            v-for="city in concreteCities"
                                            :value="city.id"
                                            :key="city.id"
                                            v-text="city.name"
                                            
                                        >
                                            
                                        </option>
                                    </select>

                                        <span class="invalid-feedback" role="alert">
                                            <strong></strong>
                                        </span>
                                </div>
                            </div>


                            <div class="form-group ">

                                    <label 
                                        :for="prefix + '_' + 'town' " 
                                        class=" col-form-label text-md-right"
                                    >
                                        Commune

                                    </label>

                                    <div class="">


                                        <select 
                                            :id="prefix + '_' + 'town'" 
                                            class="form-control form-control-sm me-input-text"
                                            :name="prefix + '_' + 'town'"
                                            required 
                                            v-model="town"
                                        >

                                            <option 
                                                :value="null" 
                                                selected
                                            >
                                                Choisir...
                                            </option>

                                            <option
                                                v-for="town in concreteTowns"
                                                :value="town.id"
                                                :key="town.id"
                                                v-text="town.name"
                                                
                                            >
                                                
                                            </option>
                                        </select>

                                            <span class="invalid-feedback" role="alert">
                                                <strong></strong>
                                            </span>
                                    </div>
                                </div>

                        </div>

                    </transition>
    </div>
</template>

<script>
    import axios from "axios";
    export default {

        props:{
            prefix:{type:String},
            territories:{type:Object},
            cities:{type:Object},
            address:{type:Object, default:null},
        },

        mounted() {

            this.territory = this.address !== null && this.address.territory_id !== undefined ? this.address.territory_id : null;
            this.city = this.address !== null && this.address.city_id !== undefined ? this.address.city_id : null;
            this.sector = this.address !== null && this.address.sector_id !== undefined ? this.address.sector_id : null;
            this.town = this.address !== null && this.address.town_id !== undefined ? this.address.town_id : null;
            this.choice = this.address !== null ? this.address.zone_type : 0;
            
        },
        watch:{
            territory(value){
                this.reloadChildrenTerritory();
            },
            city(value){
                this.reloadChildrenCity();
            }
        },
        data() {
            return {

                choice:0,
                //form
                towns:null,
                sectors:null,
                //v-model
                sector:null,
                town:null,
                territory:null,
                city:null,
            }
        },
        computed:{
                isRural(){
                    return this.choice == 0;
                },
                isUrbain(){
                    return this.choice == 1;
                },
                concreteCities(){
                        
                    let cities = this.cities === null ? [] : this.cities.data;

                    //console.log(this.cities);

                    return cities;
                },
                concreteTerritories(){
                        
                    let territories = this.territories === null ? [] : this.territories.data;

                    return territories;
                },
                concreteTowns(){
                        
                    let towns = this.towns === null ? [] : this.towns.data;

                    return towns;
                },
                concreteSectors(){
                        
                    let sectors = this.sectors === null ? [] : this.sectors.data;

                    return sectors;
                },

        },
        methods:{

            //territory


            territoryChange(){
                this.reloadChildrenProvince();

            },
            reloadChildrenTerritory(){
                this.emptyChildrenTerritory();
                this.loadChildrenTerritory();
            },
            emptyChildrenTerritory(){
                this.sectors =null;
            },
            loadChildrenTerritory(){
                this.loadSectors();
            },
            loadSectors(){

                let territory = this.territory;

                axios.get(
                    "/api/v1/territories/"+territory+"/sectors",
                )
                .then(
                    ({data}) => {
                        this.sectors = data;
                        //console.log(data);
                    }
                )
            },

            //cities


            citiesChange(){
                this.reloadChildrenCity();

            },
            reloadChildrenCity(){
                this.emptyChildrenCity();
                this.loadChildrenCity();
            },
            emptyChildrenCity(){
                this.towns =null;
            },
            loadChildrenCity(){
                this.loadTowns();
            },
            loadTowns(){

                let city = this.city;

                axios.get(
                    "/api/v1/cities/"+city+"/towns",
                )
                .then(
                    ({data}) => {
                        this.towns = data;
                        //console.log(data);
                    }
                )
            }
        },


        
    }

</script>

<style>

</style>