<template>
    <div>

        <div>

            <!--Province-->
            <div class="form-group ">
                <label :for="prefix + '_' + 'province'" class=" col-form-label text-md-right">Province</label>

                <div class="">

                    <select 
                        :id="prefix + '_' +'province' "
                        class="form-control form-control-sm me-input-text"
                        :name=' prefix + "_" +"province" '
                        @change="pronvinceChange()"
                        v-model="province"
                        required 
                    >
                        <option 
                            :value="null" 
                            :selected="address === null"
                        >
                        </option>

                        <option
                            v-for="province in concreteProvinces"
                            :value="province.id"
                            :key="province.id"
                            v-text="province.name"
                            :selected="address !== null ? address.province_id == province.id : false"
                        >
                            
                        </option>
                    </select>

                    <!--@error($prefix . "_" ."province")
                        :checked=""
                        <span class="invalid-feedback" role="alert">
                            <strong>{{ $message }}</strong>
                        </span>
                    @enderror-->
                </div>
            </div>

            
            <!--dynanmic part-->
            

            <me-address-dynamic
                :prefix="prefix"
                :territories="territories"
                :cities="cities"
                :address="address"
            >
                
            </me-address-dynamic>

            <!--dynanmic part-->

            <div class="form-group">
                <label :for=' prefix+ "_"+"references" ' class="  col-form-label text-md-right">Références</label>

                <div class="">
                    <input 
                        :id='prefix + "_"+"references" '
                        type="input" 
                        class=" me-input-text form-control form-control-sm" 
                        :name=' prefix + "_" + "references" '
                        required 
                        autocomplete="current-references"
                        :value="address !== null ? address.references : ''"
                    >

                    <!--@error($prefix. "_"."references")
                        <span class="invalid-feedback" role="alert">
                            <strong>{{ $message }}</strong>
                        </span>
                    @enderror-->

                </div>
            </div>


        </div>



    </div>
</template>

<script>
import part_dynamicVue from './part_dynamic.vue'
import axios from "axios";
    export default {

        props:{
            prefix:{type:String},
            provinces:{type:Object, default:null},
            address:{type:Object, default:null},
        },
        mounted(){
            //console.log(this.address);
            this.province = this.address !== null ? this.address.province_id : null;

            if(this.province !== null){
                this.loadChildrenProvince();
            }
            
        },
        watch:{
            address(address){
                this.province = address !== null ? address.province_id : null;
            }
        },
        components:{
            "me-address-dynamic" : part_dynamicVue
        },
        data(){
            return {
                province:null,
                territories:null,
                cities:null,
            }
        },
        computed:{

            concreteProvinces(){

                let provinces = this.provinces === null ? [] : this.provinces.data;

                return provinces;

            },

        },
        methods:{
            pronvinceChange(){
                this.reloadChildrenProvince();

            },
            reloadChildrenProvince(){
                this.emptyChildrenProvince();
                this.loadChildrenProvince();
            },
            emptyChildrenProvince(){
                this.territories =null;
                this.cities =null;
            },
            loadChildrenProvince(){
                this.loadCities();
                this.loadTerritories();
            },
            loadCities(){

                let province = this.province;

                axios.get(
                    "/api/v1/provinces/"+province+"/cities",
                )
                .then(
                    ({data}) => {
                        this.cities = data;
                        //console.log(data);
                    }
                )
            },
            loadTerritories(){

                let province = this.province;

                axios.get(
                    "/api/v1/provinces/"+province+"/territories",
                )
                .then(
                    ({data}) => {
                        this.territories = data;
                    }
                )

            }
        }
        
    }
</script>

<style scoped>

</style>