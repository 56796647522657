<template>
    <div>
                        <h6>Filtre de recherche</h6>

                        <div class="form-check form-check-inline">
                            <input 
                                class="form-check-input" 
                                type="radio" 
                                name="exampleRadios" 
                                id="exampleRadios1" 
                                v-model="show"
                                :value="true"
                                checked
                            >
                            <label 
                                class="form-check-label" 
                                for="exampleRadios1"
                            >
                                Afficher
                            </label>
                            <input 
                                class="form-check-input ml-3" 
                                type="radio" 
                                name="exampleRadios" 
                                id="exampleRadios2" 
                                v-model="show"
                                :value="false"
                            >
                            <label 
                                class="form-check-label" 
                                for="exampleRadios2"
                            >
                                Cacher
                            </label>
                        
                        </div>


                    <transition name="fade" >

                        <form 
                            :action="route" 
                            method="get"
                        >

                            <!--div v-html="csrf">

                            </!--div-->

                            <div v-show="show">

                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label 
                                                for="manager" 
                                                class=" col-form-label text-md-right"
                                            >
                                                Gérant

                                            </label>

                                            <div class="">

                                                <select 
                                                    id="manager" 
                                                    class="form-control form-control-sm me-input-text"
                                                    name="manager" 
                                                >
                                                    <option 
                                                        selected
                                                        value=""
                                                    >
                                                        Choisir...
                                                    </option>
                                                    <option
                                                        v-for="manager in managers['data']"
                                                        :key="manager.id"
                                                        :value="manager.id"
                                                    >
                                                        {{ manager.full_name }}
                                                    </option>
                                                </select>

                                                    <span class="invalid-feedback" role="alert">
                                                        <strong></strong>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label 
                                                for="driver" 
                                                class=" col-form-label text-md-right"
                                            >
                                                Chauffeur

                                            </label>

                                            <div class="">

                                                <select 
                                                    id="driver" 
                                                    class="form-control form-control-sm me-input-text"
                                                    name="driver" 
                                                >
                                                    <option 
                                                        selected
                                                        value=""
                                                    >
                                                        Choisir...
                                                    </option>
                                                    <option
                                                        v-for="driver in drivers['data']"
                                                        :key="driver.id"
                                                        :value="driver.id"
                                                    >
                                                        {{ driver.full_name }}
                                                    </option>
                                                </select>

                                                    <span class="invalid-feedback" role="alert">
                                                        <strong></strong>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label 
                                                for="help_driver" 
                                                class=" col-form-label text-md-right"
                                            >
                                                Aide chauffeur

                                            </label>

                                            <div class="">

                                                <select 
                                                    id="help_driver" 
                                                    class="form-control form-control-sm me-input-text"
                                                    name="help_driver" 
                                                >

                                                    <option 
                                                        selected
                                                        value=""
                                                    >
                                                        Choisir...
                                                    </option>
                                                    <option
                                                        v-for="help_driver in help_drivers['data']"
                                                        :key="help_driver.id"
                                                        :value="help_driver.id"
                                                    >
                                                        {{ help_driver.full_name }}
                                                    </option>
                                                </select>

                                                    <span class="invalid-feedback" role="alert">
                                                        <strong></strong>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label 
                                                for="creator" 
                                                class=" col-form-label text-md-right"
                                            >
                                                Créer par
                                            </label>

                                            <div class="">

                                                <select 
                                                    id="creator" 
                                                    class="form-control form-control-sm me-input-text"
                                                    name="creator" 
                                                >

                                                    <option 
                                                        selected
                                                        value=""
                                                    >
                                                        Choisir...
                                                    </option>
                                                    <option
                                                        v-for="creator in creators['data']"
                                                        :key="creator.id"
                                                        :value="creator.user_id"
                                                    >
                                                        {{ creator.full_name }}
                                                    </option>
                                                </select>

                                                    <span class="invalid-feedback" role="alert">
                                                        <strong></strong>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label 
                                                for="vehicle" 
                                                class=" col-form-label text-md-right"
                                            >
                                                Vehicule
                                            </label>

                                            <div class="">

                                                <select 
                                                    id="vehicle" 
                                                    class="form-control form-control-sm me-input-text"
                                                    name="vehicle" 
                                                >

                                                    <option 
                                                        selected
                                                        value=""
                                                    >
                                                        Choisir...
                                                    </option>
                                                    <option
                                                        v-for="vehicle in vehicles['data']"
                                                        :key="vehicle.id"
                                                        :value="vehicle.id"
                                                    >
                                                        {{ vehicle.ref }}
                                                    </option>
                                                </select>

                                                    <span class="invalid-feedback" role="alert">
                                                        <strong></strong>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label 
                                                for="status" 
                                                class=" col-form-label text-md-right"
                                            >
                                                Status
                                            </label>

                                            <div class="">

                                                <select 
                                                    id="status" 
                                                    class="form-control form-control-sm me-input-text"
                                                    name="status" 
                                                >
                                                    <option selected>Choose...</option>
                                                    <option>...</option>
                                                </select>

                                                    <span class="invalid-feedback" role="alert">
                                                        <strong></strong>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-9">

                                    </div>
                                    <div class="col-md-3">
                                        <button 
                                            class="btn btn-sm btn-block me-bg-red me-color-white text-bold my-3"
                                            type="submit"
                                        >
                                            Rechercher
                                        </button>
                                    </div>
                                </div>

                            </div>
                        
                        </form>

                    </transition>
                    <hr>
    </div>
</template>

<script>
    export default {
        name:"trips.search",

        props:{
            'creators':{
                type:Object,
                default:null
            }, 
            'managers':{
                type:Object,
                default:null
            }, 
            'drivers':{
                type:Object,
                default:null
            }, 
            'help_drivers':{
                type:Object,
                default:null
            }, 
            "vehicles":{
                type:Object,
                default:null
            },
            route:{
                type:String,
                default:null
            },
            csrf:{
                type:String,
                default:null
            }
        },

        mounted() {
            console.log(this.csrf)
            console.log(this.route)
            console.log("this.creators")
            console.log(this.creators)
            /*console.log(this.managers)
            console.log(this.drivers)
            console.log(this.help_drivers)
            console.log(this.vehicles)*/
        },
        data() {
            return {

                show:false,

            }
        },
        computed:{
        }
    }

</script>

<style>

</style>