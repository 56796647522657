<template>
  
  <div>
        <div :class="'side-bar  pt-5 ' + (compoShow ? 'side-bar-lg bg-white' : 'side-bar-min') ">

        <div class="pr-2 pt-4 text-right">
            <button 
              class="btn p-2 bg-white me-shadow-btn"
              @click="show"
            >
              <i 
                style="color: #707070" 
                class="fa fa-bars fa-lg"
                v-show="!compoShow"
              >
              </i>
              <i 
                style="color: #707070" 
                class="fa fa-times fa-lg"
                v-show="compoShow"
              >
              </i>
            </button>
        </div>
        
        <ul :class="'pr-2 pl-4 list-unstyled mt-3 ' + (compoShow ? '' : 'd-none') ">
            <li class="">
                <a :href=" href_trips" 
                    :class="'btn btn-block text-left me-side-bar-item me-li pb-1 mb-1 ' + active_trips">
                    Voyages
                </a>
            </li>
            <li class="">
                <a 
                    :href="href_vehicles" 
                    :class="'btn btn-block text-left me-li pb-1  mb-1 me-side-bar-item ' + active_vehicles"    
                >
                    Vehicules
                </a>

            </li>
            <li class="">
                <a 
                    :href="href_people" 
                    :class="'btn btn-block text-left me-li pb-1  mb-1 me-side-bar-item ' + active_people"    
                >
                    Personnel
                </a>

            </li>
        </ul>

    </div>
  </div>
</template>

<script>
  export default {
    name: 'side_bar',
    props:{
      href_trips:{type:String, default:'#'},
      active_trips:{type:String, default:""},
      href_vehicles:{type:String, default:'#'},
      active_vehicles:{type:String, default:""},
      href_people:{type:String, default:'#'},
      active_people:{type:String, default:""},
    },
    data() {
      return {

        compoShow : true

      }
    },
    computed: {},
    mounted() {},
    methods: {
      show(){
        this.compoShow = ! this.compoShow
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>