<template>
    <div>
                    <h6>Filtre de recherche</h6>

                    <div class="form-check form-check-inline">
                        <input 
                            class="form-check-input" 
                            type="radio" 
                            name="exampleRadios" 
                            id="exampleRadios1" 
                            v-model="show"
                            :value="true"
                            checked
                        >
                        <label 
                            class="form-check-label" 
                            for="exampleRadios1"
                        >
                            Afficher
                        </label>
                        <input 
                            class="form-check-input ml-3" 
                            type="radio" 
                            name="exampleRadios" 
                            id="exampleRadios2" 
                            v-model="show"
                            :value="false"
                        >
                        <label 
                            class="form-check-label" 
                            for="exampleRadios2"
                        >
                            Cacher
                        </label>
                    
                    </div>


                    <transition name="fade" >

                        <form :action="route" method="post">

                            <div
                                v-html="csrf"
                            >

                            </div>


                        <div v-show="show">

                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label 
                                            for="model" 
                                            class=" col-form-label text-md-right"
                                        >
                                            Modèle

                                        </label>

                                        <div class="">

                                            <select 
                                                id="model" 
                                                class="form-control form-control-sm me-input-text"
                                                name="model" 
                                            >
                                                <option selected>Choisir...</option>
                                                <option
                                                    v-for="model in models['data']"
                                                    :key="model.value"
                                                    :value="model.value"
                                                >
                                                    {{ model.text }}
                                                </option>
                                            </select>

                                                <span class="invalid-feedback" role="alert">
                                                    <strong></strong>
                                                </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label 
                                            for="brand" 
                                            class=" col-form-label text-md-right"
                                        >
                                            Marque

                                        </label>

                                        <div class="">

                                            <select 
                                                id="brand" 
                                                class="form-control form-control-sm me-input-text"
                                                name="brand" 
                                            >
                                                <option selected>Choisir...</option>
                                                <option
                                                    v-for="brand in brands['data']"
                                                    :key="brand.value"
                                                    :value="brand.value"
                                                >
                                                    {{ brand.text }}
                                                </option>
                                            </select>

                                                <span class="invalid-feedback" role="alert">
                                                    <strong></strong>
                                                </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label 
                                            for="ref" 
                                            class=" col-form-label text-md-right"
                                        >
                                            Référence

                                        </label>

                                        <div class="">

                                            <select 
                                                id="ref" 
                                                class="form-control form-control-sm me-input-text"
                                                name="ref" 
                                            >
                                                <option selected>Choose...</option>
                                                <option>...</option>
                                            </select>

                                                <span class="invalid-feedback" role="alert">
                                                    <strong></strong>
                                                </span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label 
                                            for="type" 
                                            class=" col-form-label text-md-right"
                                        >
                                            Type
                                        </label>

                                        <div class="">

                                            <select 
                                                id="type" 
                                                class="form-control form-control-sm me-input-text"
                                                name="type" 
                                            >
                                                <option selected>Choisir...</option>
                                                <option
                                                    v-for="type in types['data']"
                                                    :key="type.value"
                                                    :value="type.value"
                                                >
                                                    {{ type.text }}
                                                </option>
                                            </select>
                                                

                                                <span class="invalid-feedback" role="alert">
                                                    <strong></strong>
                                                </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label 
                                            for="year" 
                                            class=" col-form-label text-md-right"
                                        >
                                            Année
                                        </label>

                                        <div class="">

                                            <input 
                                                id="year"
                                                min="1990"
                                                type="number"
                                                step="1" 
                                                class=" me-input-text form-control form-control-sm " 
                                                name="year" 
                                                required 
                                                autocomplete="current-year"
                                            >

                                                <span class="invalid-feedback" role="alert">
                                                    <strong></strong>
                                                </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label 
                                            for="fuel" 
                                            class=" col-form-label text-md-right"
                                        >
                                            Carburant
                                        </label>

                                        <div class="">

                                            <select 
                                                id="fuel" 
                                                class="form-control form-control-sm me-input-text"
                                                name="fuel" 
                                            >
                                                <option selected>Choisir...</option>
                                                <option
                                                    v-for="fuel in fuels['data']"
                                                    :key="fuel.value"
                                                    :value="fuel.value"
                                                >
                                                    {{ fuel.text }}
                                                </option>
                                            </select>

                                                <span class="invalid-feedback" role="alert">
                                                    <strong></strong>
                                                </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label 
                                            for="creator" 
                                            class=" col-form-label text-md-right"
                                        >
                                            Créer par
                                        </label>

                                        <div class="">

                                            <select
                                                id="creator" 
                                                class="form-control form-control-sm me-input-text"
                                                name="creator" 
                                            >
                                                <option selected>Choisir...</option>
                                                <option
                                                    v-for="creator in creators['data']"
                                                    :key="creator.id"
                                                    :value="creator.id"
                                                >
                                                    {{ creator.full_name }}
                                                </option>
                                            </select>

                                                <span class="invalid-feedback" role="alert">
                                                    <strong></strong>
                                                </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-9">

                                </div>
                                <div class="col-md-3">
                                    <button 
                                        class="btn btn-sm btn-block me-bg-red me-color-white text-bold my-3"
                                        type="submit"
                                    >
                                        Rechercher
                                    </button>
                                </div>
                            </div>

                        </div>


                        </form>

                    </transition>
                    <hr>
    </div>
</template>

<script>
    export default {
        name:"trips.search",

        props:{
            brands:{
                type:Object,
                default:null
            },
            models:{
                type:Object,
                default:null
            },
            fuels:{
                type:Object,
                default:null
            },
            types:{
                type:Object,
                default:null
            },
            creators:{
                type:Object,
                default:null
            },
            route:{
                type:String
            },
            csrf:{
                type:String
            }
        },
        mounted() {
            //console.log(this.csrf)
        },
        data() {
            return {

                show:false,

            }
        },
        computed:{
        }
    }

</script>

<style>

</style>