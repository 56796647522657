require('./bootstrap');

window.Vue = require("vue").default;

//Vue.component('me-address-dynamic', require('./components/address/part_dynamic.vue').default);
Vue.component('me-address', require('./components/address/index.vue').default);
Vue.component('me-trips-search-filter', require('./components/trips/search_filter.vue').default);
Vue.component('me-vehicles-search-filter', require('./components/vehicles/search_filter.vue').default);
Vue.component('me-side-bar', require('./components/side_bar.vue').default);

let app = new Vue({
    
    el:"#app",
    created(){
        
    }
})

var mybutton = document.getElementById("myBtn");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    mybutton.style.display = "block";
  } else {
    mybutton.style.display = "none";
  }
}

// When the user clicks on the button, scroll to the top of the document
window.topFunction = function() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}
